'use client';
import { createIcon } from '@chakra-ui/icons';

// reexport some common icons from react-icons to better support ssr
export {
    ArrowRightIcon,
    ExternalLinkIcon,
    QuestionOutlineIcon,
    SettingsIcon,
    WarningIcon,
    WarningTwoIcon,
} from '@chakra-ui/icons';
export { FiBell, FiFilePlus, FiSearch } from 'react-icons/fi';
export { HiOutlineArrowNarrowRight } from 'react-icons/hi';
export { HiMiniUserCircle } from 'react-icons/hi2';

export const ItemIcon = createIcon({
    displayName: 'ItemIcon',
    viewBox: '0 0 24 24',
    path: (
        <>
            <path fill="none" d="M0 0h24v24H0z"></path>,
            <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
        </>
    ),
});

export const BuyXGetYIconImage = `
    <svg viewBox="0 0 34 34" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
       <path
            d="M24.0834 8.5C19.3942 8.5 15.5834 12.3108 15.5834 17C15.5834 21.6892 19.3942 25.5 24.0834 25.5C28.7725 25.5 32.5834 21.6892 32.5834 17C32.5834 12.3108 28.7725 8.5 24.0834 8.5ZM7.08335 11.3333C3.95252 11.3333 1.41669 13.8692 1.41669 17C1.41669 20.1308 3.95252 22.6667 7.08335 22.6667C10.2142 22.6667 12.75 20.1308 12.75 17C12.75 13.8692 10.2142 11.3333 7.08335 11.3333ZM7.08335 19.8333C5.52502 19.8333 4.25002 18.5583 4.25002 17C4.25002 15.4417 5.52502 14.1667 7.08335 14.1667C8.64169 14.1667 9.91669 15.4417 9.91669 17C9.91669 18.5583 8.64169 19.8333 7.08335 19.8333Z"
            fill="currentColor"
        />
    </svg>`;

export const BuyXGetYIcon = createIcon({
    displayName: 'BuyXGetYIcon',
    viewBox: '0 0 34 34',
    path: (
        <path
            d="M24.0834 8.5C19.3942 8.5 15.5834 12.3108 15.5834 17C15.5834 21.6892 19.3942 25.5 24.0834 25.5C28.7725 25.5 32.5834 21.6892 32.5834 17C32.5834 12.3108 28.7725 8.5 24.0834 8.5ZM7.08335 11.3333C3.95252 11.3333 1.41669 13.8692 1.41669 17C1.41669 20.1308 3.95252 22.6667 7.08335 22.6667C10.2142 22.6667 12.75 20.1308 12.75 17C12.75 13.8692 10.2142 11.3333 7.08335 11.3333ZM7.08335 19.8333C5.52502 19.8333 4.25002 18.5583 4.25002 17C4.25002 15.4417 5.52502 14.1667 7.08335 14.1667C8.64169 14.1667 9.91669 15.4417 9.91669 17C9.91669 18.5583 8.64169 19.8333 7.08335 19.8333Z"
            fill="currentColor"
        />
    ),
});

export const DiscountIconImage = `
    <svg viewBox="0 0 28 28" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.7576 2.68945H7.37827C6.08862 2.68945 5.03345 3.74463 5.03345 5.03428V23.7929C5.03345 25.0826 6.08862 26.1377 7.37827 26.1377H21.4472C22.7369 26.1377 23.7921 25.0826 23.7921 23.7929V9.72394L16.7576 2.68945ZM7.37827 23.7929V5.03428H15.5852V9.72394H21.4472V23.7929H7.37827ZM13.2403 22.6205H15.5852V21.4481H16.7576C17.4024 21.4481 17.93 20.9205 17.93 20.2757V16.7584C17.93 16.1136 17.4024 15.586 16.7576 15.586H13.2403V14.4136H17.93V12.0688H15.5852V10.8963H13.2403V12.0688H12.0679C11.4231 12.0688 10.8955 12.5963 10.8955 13.2412V16.7584C10.8955 17.4032 11.4231 17.9308 12.0679 17.9308H15.5852V19.1032H10.8955V21.4481H13.2403V22.6205Z"
            fill="currentColor"
        />
    </svg>
`;

export const DiscountIcon = createIcon({
    displayName: 'DiscountIcon',
    viewBox: '0 0 28 28',
    path: (
        <path
            d="M16.7576 2.68945H7.37827C6.08862 2.68945 5.03345 3.74463 5.03345 5.03428V23.7929C5.03345 25.0826 6.08862 26.1377 7.37827 26.1377H21.4472C22.7369 26.1377 23.7921 25.0826 23.7921 23.7929V9.72394L16.7576 2.68945ZM7.37827 23.7929V5.03428H15.5852V9.72394H21.4472V23.7929H7.37827ZM13.2403 22.6205H15.5852V21.4481H16.7576C17.4024 21.4481 17.93 20.9205 17.93 20.2757V16.7584C17.93 16.1136 17.4024 15.586 16.7576 15.586H13.2403V14.4136H17.93V12.0688H15.5852V10.8963H13.2403V12.0688H12.0679C11.4231 12.0688 10.8955 12.5963 10.8955 13.2412V16.7584C10.8955 17.4032 11.4231 17.9308 12.0679 17.9308H15.5852V19.1032H10.8955V21.4481H13.2403V22.6205Z"
            fill="currentColor"
        />
    ),
});

export const PercentageOffIconImage = `
    <svg viewBox="0 0 34 34" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
                d="M10.9583 5.66669C8.22417 5.66669 6 7.89085 6 10.625C6 13.3592 8.22417 15.5834 10.9583 15.5834C13.6925 15.5834 15.9167 13.3592 15.9167 10.625C15.9167 7.89085 13.6925 5.66669 10.9583 5.66669ZM10.9583 12.75C9.7825 12.75 8.83333 11.8009 8.83333 10.625C8.83333 9.44919 9.7825 8.50002 10.9583 8.50002C12.1342 8.50002 13.0833 9.44919 13.0833 10.625C13.0833 11.8009 12.1342 12.75 10.9583 12.75ZM23.7083 18.4167C20.9742 18.4167 18.75 20.6409 18.75 23.375C18.75 26.1092 20.9742 28.3334 23.7083 28.3334C26.4425 28.3334 28.6667 26.1092 28.6667 23.375C28.6667 20.6409 26.4425 18.4167 23.7083 18.4167ZM23.7083 25.5C22.5325 25.5 21.5833 24.5509 21.5833 23.375C21.5833 22.1992 22.5325 21.25 23.7083 21.25C24.8842 21.25 25.8333 22.1992 25.8333 23.375C25.8333 24.5509 24.8842 25.5 23.7083 25.5ZM7.9975 28.3334L6 26.3359L26.6692 5.66669L28.6667 7.66419L7.9975 28.3334Z"
                fill="currentColor"
            />
    </svg>
`;

export const PercentageOffIcon = createIcon({
    displayName: 'PercentageIcon',
    viewBox: '0 0 34 34',
    path: (
        <path
            d="M10.9583 5.66669C8.22417 5.66669 6 7.89085 6 10.625C6 13.3592 8.22417 15.5834 10.9583 15.5834C13.6925 15.5834 15.9167 13.3592 15.9167 10.625C15.9167 7.89085 13.6925 5.66669 10.9583 5.66669ZM10.9583 12.75C9.7825 12.75 8.83333 11.8009 8.83333 10.625C8.83333 9.44919 9.7825 8.50002 10.9583 8.50002C12.1342 8.50002 13.0833 9.44919 13.0833 10.625C13.0833 11.8009 12.1342 12.75 10.9583 12.75ZM23.7083 18.4167C20.9742 18.4167 18.75 20.6409 18.75 23.375C18.75 26.1092 20.9742 28.3334 23.7083 28.3334C26.4425 28.3334 28.6667 26.1092 28.6667 23.375C28.6667 20.6409 26.4425 18.4167 23.7083 18.4167ZM23.7083 25.5C22.5325 25.5 21.5833 24.5509 21.5833 23.375C21.5833 22.1992 22.5325 21.25 23.7083 21.25C24.8842 21.25 25.8333 22.1992 25.8333 23.375C25.8333 24.5509 24.8842 25.5 23.7083 25.5ZM7.9975 28.3334L6 26.3359L26.6692 5.66669L28.6667 7.66419L7.9975 28.3334Z"
            fill="currentColor"
        />
    ),
});

export const DisplayIconImage = `
    <svg viewBox="0 0 34 34" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M31.6886 12.5942L30.2011 6.40333C29.8895 5.12833 28.7845 4.25 27.4953 4.25H7.81781C6.54281 4.25 5.42364 5.1425 5.12614 6.40333L3.63864 12.5942C3.29864 14.0392 3.61031 15.5125 4.51698 16.6742C4.63031 16.83 4.78614 16.9433 4.91364 17.085V26.9167C4.91364 28.475 6.18864 29.75 7.74698 29.75H27.5803C29.1386 29.75 30.4136 28.475 30.4136 26.9167V17.085C30.5411 16.9575 30.697 16.83 30.8103 16.6883C31.717 15.5267 32.0428 14.0392 31.6886 12.5942ZM27.4528 7.06917L28.9403 13.26C29.082 13.855 28.9545 14.45 28.5861 14.9175C28.3878 15.1725 27.9628 15.5833 27.2545 15.5833C26.3903 15.5833 25.6395 14.8892 25.5403 13.9683L24.7186 7.08333L27.4528 7.06917ZM19.0803 7.08333H21.857L22.622 13.4867C22.6928 14.0392 22.5228 14.5917 22.1545 15.0025C21.8428 15.3708 21.3895 15.5833 20.8086 15.5833C19.8595 15.5833 19.0803 14.7475 19.0803 13.7275V7.08333ZM12.6911 13.4867L13.4703 7.08333H16.247V13.7275C16.247 14.7475 15.4678 15.5833 14.4195 15.5833C13.9378 15.5833 13.4986 15.3708 13.1586 15.0025C12.8045 14.5917 12.6345 14.0392 12.6911 13.4867ZM6.38698 13.26L7.81781 7.08333H10.6086L9.78698 13.9683C9.67364 14.8892 8.93698 15.5833 8.07281 15.5833C7.37864 15.5833 6.93948 15.1725 6.75531 14.9175C6.37281 14.4642 6.24531 13.855 6.38698 13.26ZM7.74698 26.9167V18.3742C7.86031 18.3883 7.95948 18.4167 8.07281 18.4167C9.30531 18.4167 10.4245 17.9067 11.2461 17.0708C12.0961 17.9208 13.2295 18.4167 14.5186 18.4167C15.7511 18.4167 16.8561 17.9067 17.6778 17.0992C18.5136 17.9067 19.647 18.4167 20.922 18.4167C22.112 18.4167 23.2453 17.9208 24.0953 17.0708C24.917 17.9067 26.0361 18.4167 27.2686 18.4167C27.382 18.4167 27.4811 18.3883 27.5945 18.3742V26.9167H7.74698Z"
            fill="currentColor"
        />
    </svg>
`;

export const DisplayIcon = createIcon({
    displayName: 'DisplayIcon',
    viewBox: '0 0 34 34',
    path: (
        <path
            d="M31.6886 12.5942L30.2011 6.40333C29.8895 5.12833 28.7845 4.25 27.4953 4.25H7.81781C6.54281 4.25 5.42364 5.1425 5.12614 6.40333L3.63864 12.5942C3.29864 14.0392 3.61031 15.5125 4.51698 16.6742C4.63031 16.83 4.78614 16.9433 4.91364 17.085V26.9167C4.91364 28.475 6.18864 29.75 7.74698 29.75H27.5803C29.1386 29.75 30.4136 28.475 30.4136 26.9167V17.085C30.5411 16.9575 30.697 16.83 30.8103 16.6883C31.717 15.5267 32.0428 14.0392 31.6886 12.5942ZM27.4528 7.06917L28.9403 13.26C29.082 13.855 28.9545 14.45 28.5861 14.9175C28.3878 15.1725 27.9628 15.5833 27.2545 15.5833C26.3903 15.5833 25.6395 14.8892 25.5403 13.9683L24.7186 7.08333L27.4528 7.06917ZM19.0803 7.08333H21.857L22.622 13.4867C22.6928 14.0392 22.5228 14.5917 22.1545 15.0025C21.8428 15.3708 21.3895 15.5833 20.8086 15.5833C19.8595 15.5833 19.0803 14.7475 19.0803 13.7275V7.08333ZM12.6911 13.4867L13.4703 7.08333H16.247V13.7275C16.247 14.7475 15.4678 15.5833 14.4195 15.5833C13.9378 15.5833 13.4986 15.3708 13.1586 15.0025C12.8045 14.5917 12.6345 14.0392 12.6911 13.4867ZM6.38698 13.26L7.81781 7.08333H10.6086L9.78698 13.9683C9.67364 14.8892 8.93698 15.5833 8.07281 15.5833C7.37864 15.5833 6.93948 15.1725 6.75531 14.9175C6.37281 14.4642 6.24531 13.855 6.38698 13.26ZM7.74698 26.9167V18.3742C7.86031 18.3883 7.95948 18.4167 8.07281 18.4167C9.30531 18.4167 10.4245 17.9067 11.2461 17.0708C12.0961 17.9208 13.2295 18.4167 14.5186 18.4167C15.7511 18.4167 16.8561 17.9067 17.6778 17.0992C18.5136 17.9067 19.647 18.4167 20.922 18.4167C22.112 18.4167 23.2453 17.9208 24.0953 17.0708C24.917 17.9067 26.0361 18.4167 27.2686 18.4167C27.382 18.4167 27.4811 18.3883 27.5945 18.3742V26.9167H7.74698Z"
            fill="currentColor"
        />
    ),
});

export const FeatureIconImage = `
 <svg viewBox="0 0 34 34" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.91668 4.25002H5.66668V8.50002H2.83334V1.41669H9.91668V4.25002ZM31.1667 8.50002V1.41669H24.0833V4.25002H28.3333V8.50002H31.1667ZM9.91668 29.75H5.66668V25.5H2.83334V32.5834H9.91668V29.75ZM28.3333 25.5V29.75H24.0833V32.5834H31.1667V25.5H28.3333ZM24.0833 8.50002H9.91668V25.5H24.0833V8.50002ZM26.9167 25.5C26.9167 27.0584 25.6417 28.3334 24.0833 28.3334H9.91668C8.35834 28.3334 7.08334 27.0584 7.08334 25.5V8.50002C7.08334 6.94169 8.35834 5.66669 9.91668 5.66669H24.0833C25.6417 5.66669 26.9167 6.94169 26.9167 8.50002V25.5ZM21.25 11.3334H12.75V14.1667H21.25V11.3334ZM21.25 15.5834H12.75V18.4167H21.25V15.5834ZM21.25 19.8334H12.75V22.6667H21.25V19.8334Z"
            fill="currentColor"
        />
    </svg>
`;

export const FeatureIcon = createIcon({
    displayName: 'FeatureIcon',
    viewBox: '0 0 34 34',
    path: (
        <path
            d="M9.91668 4.25002H5.66668V8.50002H2.83334V1.41669H9.91668V4.25002ZM31.1667 8.50002V1.41669H24.0833V4.25002H28.3333V8.50002H31.1667ZM9.91668 29.75H5.66668V25.5H2.83334V32.5834H9.91668V29.75ZM28.3333 25.5V29.75H24.0833V32.5834H31.1667V25.5H28.3333ZM24.0833 8.50002H9.91668V25.5H24.0833V8.50002ZM26.9167 25.5C26.9167 27.0584 25.6417 28.3334 24.0833 28.3334H9.91668C8.35834 28.3334 7.08334 27.0584 7.08334 25.5V8.50002C7.08334 6.94169 8.35834 5.66669 9.91668 5.66669H24.0833C25.6417 5.66669 26.9167 6.94169 26.9167 8.50002V25.5ZM21.25 11.3334H12.75V14.1667H21.25V11.3334ZM21.25 15.5834H12.75V18.4167H21.25V15.5834ZM21.25 19.8334H12.75V22.6667H21.25V19.8334Z"
            fill="currentColor"
        />
    ),
});

export const DisplayAndFeatureIconImage = `
 <svg viewBox="0 0 34 34" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
            d="M28.6666 11.3334H24.4166V5.66669H4.58331C3.02498 5.66669 1.74998 6.94169 1.74998 8.50002V24.0834H4.58331C4.58331 26.435 6.48165 28.3334 8.83331 28.3334C11.185 28.3334 13.0833 26.435 13.0833 24.0834H21.5833C21.5833 26.435 23.4816 28.3334 25.8333 28.3334C28.185 28.3334 30.0833 26.435 30.0833 24.0834H32.9166V17L28.6666 11.3334ZM27.9583 13.4584L30.735 17H24.4166V13.4584H27.9583ZM8.83331 25.5C8.05415 25.5 7.41665 24.8625 7.41665 24.0834C7.41665 23.3042 8.05415 22.6667 8.83331 22.6667C9.61248 22.6667 10.25 23.3042 10.25 24.0834C10.25 24.8625 9.61248 25.5 8.83331 25.5ZM11.9783 21.25C11.1991 20.3859 10.0941 19.8334 8.83331 19.8334C7.57248 19.8334 6.46748 20.3859 5.68831 21.25H4.58331V8.50002H21.5833V21.25H11.9783ZM25.8333 25.5C25.0541 25.5 24.4166 24.8625 24.4166 24.0834C24.4166 23.3042 25.0541 22.6667 25.8333 22.6667C26.6125 22.6667 27.25 23.3042 27.25 24.0834C27.25 24.8625 26.6125 25.5 25.8333 25.5Z"
            fill="currentColor"
        />
</svg>
`;

export const DisplayAndFeatureIcon = createIcon({
    displayName: 'DisplayAndFeatureIcon',
    viewBox: '0 0 34 34',
    path: (
        <path
            d="M28.6666 11.3334H24.4166V5.66669H4.58331C3.02498 5.66669 1.74998 6.94169 1.74998 8.50002V24.0834H4.58331C4.58331 26.435 6.48165 28.3334 8.83331 28.3334C11.185 28.3334 13.0833 26.435 13.0833 24.0834H21.5833C21.5833 26.435 23.4816 28.3334 25.8333 28.3334C28.185 28.3334 30.0833 26.435 30.0833 24.0834H32.9166V17L28.6666 11.3334ZM27.9583 13.4584L30.735 17H24.4166V13.4584H27.9583ZM8.83331 25.5C8.05415 25.5 7.41665 24.8625 7.41665 24.0834C7.41665 23.3042 8.05415 22.6667 8.83331 22.6667C9.61248 22.6667 10.25 23.3042 10.25 24.0834C10.25 24.8625 9.61248 25.5 8.83331 25.5ZM11.9783 21.25C11.1991 20.3859 10.0941 19.8334 8.83331 19.8334C7.57248 19.8334 6.46748 20.3859 5.68831 21.25H4.58331V8.50002H21.5833V21.25H11.9783ZM25.8333 25.5C25.0541 25.5 24.4166 24.8625 24.4166 24.0834C24.4166 23.3042 25.0541 22.6667 25.8333 22.6667C26.6125 22.6667 27.25 23.3042 27.25 24.0834C27.25 24.8625 26.6125 25.5 25.8333 25.5Z"
            fill="currentColor"
        />
    ),
});

export const EDLPIconImage = `
 <svg viewBox="0 0 34 34" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
                d="M30.9975 16.405L18.2475 3.65498C17.7375 3.14498 17.0291 2.83331 16.25 2.83331H6.33329C4.77495 2.83331 3.49995 4.10831 3.49995 5.66665V15.5833C3.49995 16.3625 3.81162 17.0708 4.33579 17.595L17.0858 30.345C17.5958 30.855 18.3041 31.1666 19.0833 31.1666C19.8625 31.1666 20.5708 30.855 21.0808 30.3308L30.9975 20.4141C31.5216 19.9041 31.8333 19.1958 31.8333 18.4166C31.8333 17.6375 31.5075 16.915 30.9975 16.405ZM19.0833 28.3475L6.33329 15.5833V5.66665H16.25V5.65248L29 18.4025L19.0833 28.3475Z"
                fill="currentColor"
    />
    <path
        d="M9.87495 11.3333C11.0486 11.3333 12 10.3819 12 9.20831C12 8.03471 11.0486 7.08331 9.87495 7.08331C8.70135 7.08331 7.74995 8.03471 7.74995 9.20831C7.74995 10.3819 8.70135 11.3333 9.87495 11.3333Z"
        fill="currentColor"
    />
</svg>
`;

export const EDLPIcon = createIcon({
    displayName: 'EDLPIcon',
    viewBox: '0 0 34 34',
    path: (
        <>
            <path
                d="M30.9975 16.405L18.2475 3.65498C17.7375 3.14498 17.0291 2.83331 16.25 2.83331H6.33329C4.77495 2.83331 3.49995 4.10831 3.49995 5.66665V15.5833C3.49995 16.3625 3.81162 17.0708 4.33579 17.595L17.0858 30.345C17.5958 30.855 18.3041 31.1666 19.0833 31.1666C19.8625 31.1666 20.5708 30.855 21.0808 30.3308L30.9975 20.4141C31.5216 19.9041 31.8333 19.1958 31.8333 18.4166C31.8333 17.6375 31.5075 16.915 30.9975 16.405ZM19.0833 28.3475L6.33329 15.5833V5.66665H16.25V5.65248L29 18.4025L19.0833 28.3475Z"
                fill="currentColor"
            />
            <path
                d="M9.87495 11.3333C11.0486 11.3333 12 10.3819 12 9.20831C12 8.03471 11.0486 7.08331 9.87495 7.08331C8.70135 7.08331 7.74995 8.03471 7.74995 9.20831C7.74995 10.3819 8.70135 11.3333 9.87495 11.3333Z"
                fill="currentColor"
            />
        </>
    ),
});

export const EachIcon = createIcon({
    displayName: 'EachIcon',
    viewBox: '0 0 24 24',
    path: (
        <>
            <path fill="none" d="M0 0h24v24H0V0z"></path>
            <path d="M9 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zm8-13.74v2.09a5.99 5.99 0 0 1 0 11.3v2.09c3.45-.89 6-4.01 6-7.74 0-3.73-2.55-6.85-6-7.74z"></path>
        </>
    ),
});

export const PackIcon = createIcon({
    displayName: 'PackIcon',
    viewBox: '0 0 24 24',
    path: (
        <>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M6 15c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0-2c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm6-8c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0-2C9.8 3 8 4.8 8 7s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm6 12c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0-2c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4z"></path>
        </>
    ),
});

export const LogisticUnitIcon = createIcon({
    displayName: 'LogisticUnitIcon',
    viewBox: '0 0 24 24',
    path: (
        <>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M7.02 13c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM13 13v8h8v-8h-8zm6 6h-4v-4h4v4zM7 2l-5 9h10L7 2zm0 4.12L8.6 9H5.4L7 6.12zM19.25 2.5c-1.06 0-1.81.56-2.25 1.17-.44-.61-1.19-1.17-2.25-1.17C13.19 2.5 12 3.78 12 5.25c0 2 2.42 3.42 5 5.75 2.58-2.33 5-3.75 5-5.75 0-1.47-1.19-2.75-2.75-2.75zM17 8.35c-1.45-1.22-3-2.4-3-3.1 0-.43.35-.75.75-.75.31 0 .52.17.73.37L17 6.3l1.52-1.43c.21-.2.42-.37.73-.37.4 0 .75.32.75.75 0 .7-1.55 1.88-3 3.1z"></path>
        </>
    ),
});

export const FixedPriceIconImage = `
 <svg viewBox="0 0 34 34" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
       <path
            d="M18.0525 15.4417C14.8367 14.6058 13.8025 13.7417 13.8025 12.3958C13.8025 10.8517 15.2334 9.775 17.6275 9.775C20.1492 9.775 21.0842 10.9792 21.1692 12.75H24.3C24.2009 10.3133 22.7134 8.075 19.7525 7.3525V4.25H15.5025V7.31C12.7542 7.905 10.5442 9.69 10.5442 12.4242C10.5442 15.6967 13.25 17.3258 17.2025 18.275C20.7442 19.125 21.4525 20.3717 21.4525 21.6892C21.4525 22.6667 20.7584 24.225 17.6275 24.225C14.7092 24.225 13.5617 22.9217 13.4059 21.25H10.2892C10.4592 24.3525 12.7825 26.095 15.5025 26.6758V29.75H19.7525V26.7042C22.515 26.18 24.7109 24.5792 24.7109 21.675C24.7109 17.6517 21.2684 16.2775 18.0525 15.4417Z"
            fill="currentColor"
        />
</svg>
`;

export const FixedPriceIcon = createIcon({
    displayName: 'FixedPriceIcon',
    viewBox: '0 0 34 34',
    path: (
        <path
            d="M18.0525 15.4417C14.8367 14.6058 13.8025 13.7417 13.8025 12.3958C13.8025 10.8517 15.2334 9.775 17.6275 9.775C20.1492 9.775 21.0842 10.9792 21.1692 12.75H24.3C24.2009 10.3133 22.7134 8.075 19.7525 7.3525V4.25H15.5025V7.31C12.7542 7.905 10.5442 9.69 10.5442 12.4242C10.5442 15.6967 13.25 17.3258 17.2025 18.275C20.7442 19.125 21.4525 20.3717 21.4525 21.6892C21.4525 22.6667 20.7584 24.225 17.6275 24.225C14.7092 24.225 13.5617 22.9217 13.4059 21.25H10.2892C10.4592 24.3525 12.7825 26.095 15.5025 26.6758V29.75H19.7525V26.7042C22.515 26.18 24.7109 24.5792 24.7109 21.675C24.7109 17.6517 21.2684 16.2775 18.0525 15.4417Z"
            fill="currentColor"
        />
    ),
});

export const SlottingFeeIconImage = `   
 <svg viewBox="0 0 34 34" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M28.3333 9.20829H24.0833V6.37496C24.0833 4.80246 22.8225 3.54163 21.25 3.54163H12.75C11.1775 3.54163 9.91666 4.80246 9.91666 6.37496V9.20829H5.66666C4.09416 9.20829 2.83333 10.4691 2.83333 12.0416V27.625C2.83333 29.1975 4.09416 30.4583 5.66666 30.4583H28.3333C29.9058 30.4583 31.1667 29.1975 31.1667 27.625V12.0416C31.1667 10.4691 29.9058 9.20829 28.3333 9.20829ZM12.75 6.37496H21.25V9.20829H12.75V6.37496ZM28.3333 27.625H5.66666V24.7916H28.3333V27.625ZM28.3333 20.5416H5.66666V12.0416H9.91666V14.875H12.75V12.0416H21.25V14.875H24.0833V12.0416H28.3333V20.5416Z"
            fill="currentColor"
        />
</svg>
`;

export const SlottingFeeIcon = createIcon({
    displayName: 'SlottingIcon',
    viewBox: '0 0 34 34',
    path: (
        <path
            d="M28.3333 9.20829H24.0833V6.37496C24.0833 4.80246 22.8225 3.54163 21.25 3.54163H12.75C11.1775 3.54163 9.91666 4.80246 9.91666 6.37496V9.20829H5.66666C4.09416 9.20829 2.83333 10.4691 2.83333 12.0416V27.625C2.83333 29.1975 4.09416 30.4583 5.66666 30.4583H28.3333C29.9058 30.4583 31.1667 29.1975 31.1667 27.625V12.0416C31.1667 10.4691 29.9058 9.20829 28.3333 9.20829ZM12.75 6.37496H21.25V9.20829H12.75V6.37496ZM28.3333 27.625H5.66666V24.7916H28.3333V27.625ZM28.3333 20.5416H5.66666V12.0416H9.91666V14.875H12.75V12.0416H21.25V14.875H24.0833V12.0416H28.3333V20.5416Z"
            fill="currentColor"
        />
    ),
});

export const DeliveryFeeIconImage = `   
 <svg viewBox="0 0 34 34" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M24.5833 7.08337H4.75C3.19167 7.08337 1.91667 8.34421 1.91667 9.91671V22.6667H4.75C4.75 25.0184 6.64833 26.9167 9 26.9167C11.3517 26.9167 13.25 25.0184 13.25 22.6667H21.75C21.75 25.0184 23.6483 26.9167 26 26.9167C28.3517 26.9167 30.25 25.0184 30.25 22.6667H33.0833V15.5834L24.5833 7.08337ZM21.75 9.91671H23.1667L27.4167 14.1667H21.75V9.91671ZM13.25 9.91671H18.9167V14.1667H13.25V9.91671ZM4.75 9.91671H10.4167V14.1667H4.75V9.91671ZM9 24.4375C8.0225 24.4375 7.22917 23.6442 7.22917 22.6667C7.22917 21.6892 8.0225 20.8959 9 20.8959C9.9775 20.8959 10.7708 21.6892 10.7708 22.6667C10.7708 23.6442 9.9775 24.4375 9 24.4375ZM26 24.4375C25.0225 24.4375 24.2292 23.6442 24.2292 22.6667C24.2292 21.6892 25.0225 20.8959 26 20.8959C26.9775 20.8959 27.7708 21.6892 27.7708 22.6667C27.7708 23.6442 26.9775 24.4375 26 24.4375ZM30.25 19.8334H29.145C28.3658 18.9692 27.2467 18.4167 26 18.4167C24.7533 18.4167 23.6342 18.9692 22.855 19.8334H12.145C11.3658 18.9692 10.2608 18.4167 9 18.4167C7.73917 18.4167 6.63417 18.9692 5.855 19.8334H4.75V17H30.25V19.8334Z"
            fill="currentColor"
        />
</svg>
`;

export const DeliveryFeeIcon = createIcon({
    displayName: 'DeliveryIcon',
    viewBox: '0 0 34 34',
    path: (
        <path
            d="M24.5833 7.08337H4.75C3.19167 7.08337 1.91667 8.34421 1.91667 9.91671V22.6667H4.75C4.75 25.0184 6.64833 26.9167 9 26.9167C11.3517 26.9167 13.25 25.0184 13.25 22.6667H21.75C21.75 25.0184 23.6483 26.9167 26 26.9167C28.3517 26.9167 30.25 25.0184 30.25 22.6667H33.0833V15.5834L24.5833 7.08337ZM21.75 9.91671H23.1667L27.4167 14.1667H21.75V9.91671ZM13.25 9.91671H18.9167V14.1667H13.25V9.91671ZM4.75 9.91671H10.4167V14.1667H4.75V9.91671ZM9 24.4375C8.0225 24.4375 7.22917 23.6442 7.22917 22.6667C7.22917 21.6892 8.0225 20.8959 9 20.8959C9.9775 20.8959 10.7708 21.6892 10.7708 22.6667C10.7708 23.6442 9.9775 24.4375 9 24.4375ZM26 24.4375C25.0225 24.4375 24.2292 23.6442 24.2292 22.6667C24.2292 21.6892 25.0225 20.8959 26 20.8959C26.9775 20.8959 27.7708 21.6892 27.7708 22.6667C27.7708 23.6442 26.9775 24.4375 26 24.4375ZM30.25 19.8334H29.145C28.3658 18.9692 27.2467 18.4167 26 18.4167C24.7533 18.4167 23.6342 18.9692 22.855 19.8334H12.145C11.3658 18.9692 10.2608 18.4167 9 18.4167C7.73917 18.4167 6.63417 18.9692 5.855 19.8334H4.75V17H30.25V19.8334Z"
            fill="currentColor"
        />
    ),
});

export const CustomIconImage = `
 <svg viewBox="0 0 34 34" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
       <path
            d="M9.10018 14.1666C7.54185 14.1666 6.26685 15.4416 6.26685 17C6.26685 18.5583 7.54185 19.8333 9.10018 19.8333C10.6585 19.8333 11.9335 18.5583 11.9335 17C11.9335 15.4416 10.6585 14.1666 9.10018 14.1666ZM26.1002 14.1666C24.5418 14.1666 23.2668 15.4416 23.2668 17C23.2668 18.5583 24.5418 19.8333 26.1002 19.8333C27.6585 19.8333 28.9335 18.5583 28.9335 17C28.9335 15.4416 27.6585 14.1666 26.1002 14.1666ZM17.6002 14.1666C16.0418 14.1666 14.7668 15.4416 14.7668 17C14.7668 18.5583 16.0418 19.8333 17.6002 19.8333C19.1585 19.8333 20.4335 18.5583 20.4335 17C20.4335 15.4416 19.1585 14.1666 17.6002 14.1666Z"
            fill="currentColor"
        />
</svg>
`;

export const CustomIcon = createIcon({
    displayName: 'CustomIcon',
    viewBox: '0 0 34 34',
    path: (
        <path
            d="M9.10018 14.1666C7.54185 14.1666 6.26685 15.4416 6.26685 17C6.26685 18.5583 7.54185 19.8333 9.10018 19.8333C10.6585 19.8333 11.9335 18.5583 11.9335 17C11.9335 15.4416 10.6585 14.1666 9.10018 14.1666ZM26.1002 14.1666C24.5418 14.1666 23.2668 15.4416 23.2668 17C23.2668 18.5583 24.5418 19.8333 26.1002 19.8333C27.6585 19.8333 28.9335 18.5583 28.9335 17C28.9335 15.4416 27.6585 14.1666 26.1002 14.1666ZM17.6002 14.1666C16.0418 14.1666 14.7668 15.4416 14.7668 17C14.7668 18.5583 16.0418 19.8333 17.6002 19.8333C19.1585 19.8333 20.4335 18.5583 20.4335 17C20.4335 15.4416 19.1585 14.1666 17.6002 14.1666Z"
            fill="currentColor"
        />
    ),
});

export const CannibalizationIcon = createIcon({
    displayName: 'CannibalizationIcon',
    viewBox: '0 0 34 34',
    path: (
        <path
            d="M22.8667 15.5833V25.4575L15.7834 22.4258L8.70003 25.4575V7.08333H17.2V4.25H8.70003C7.1417 4.25 5.8667 5.525 5.8667 7.08333V29.75L15.7834 25.5L25.7 29.75V15.5833H22.8667ZM28.5334 9.91667H20.0334V7.08333H28.5334V9.91667Z"
            fill="currentColor"
        />
    ),
});
